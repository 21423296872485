<template>
 <div class="nk-block">
        <div class="card card-bordered  p-3">
      This is where the overall platform is managed from. Any global settings. Template emails etc etc.
        </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>